import { useState } from "react"

import { InputProps, TextField, TextFieldProps } from "@material-ui/core/"

import {
  ISettingContainerBaseProps,
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "./SettingContainer"

export function SettingText({
  title,
  description,
  value,
  onSave,
  textFieldProps,
  inputProps,
  ...props
}: ISettingContainerBaseProps & {
  textFieldProps?: TextFieldProps
  inputProps?: InputProps["inputProps"]
  onSave: (newVal: string) => TSettingContainerOnSaveReturnType
  value: string
}) {
  const [saveValue, setSaveValue] = useState(value)

  return (
    <SettingContainer
      title={title}
      description={description}
      onSave={async () => {
        return await onSave(String(saveValue))
      }}
      displayValue={String(value)}
      InputComponent={({ saving }) => (
        <Input
          valueInitial={value}
          setSaveValue={setSaveValue}
          disabled={saving}
          {...textFieldProps}
          inputProps={inputProps}
        />
      )}
      {...props}
    />
  )
}

function Input({
  valueInitial,
  setSaveValue,
  inputProps,
  ...textFieldProps
}: {
  valueInitial: string
  setSaveValue(newVal: string): void
  inputProps?: InputProps["inputProps"]
} & TextFieldProps) {
  const [_value, _setValue] = useState(valueInitial)
  return (
    <TextField
      value={_value}
      onChange={(e) => {
        _setValue(e.target.value)
        setSaveValue(e.target.value)
      }}
      fullWidth
      {...textFieldProps}
      inputProps={inputProps}
    />
  )
}
